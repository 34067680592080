import { IconProps } from "./interface";
import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 66 / 81;

export function MagicWand(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 81 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M62.082 5.60498L47.082 20.73L53.332 27.105L68.457 11.98C68.832 11.605 68.832 10.98 68.457 10.605L63.457 5.60498C63.082 5.22998 62.457 5.22998 62.082 5.60498ZM13.082 54.605C12.707 54.98 12.707 55.605 13.082 55.98L18.082 60.98C18.457 61.355 19.082 61.355 19.457 60.98L50.582 29.855L44.207 23.48L13.082 54.605ZM59.207 2.85498C61.207 0.85498 64.332 0.85498 66.332 2.85498L71.207 7.72998C73.207 9.72998 73.207 12.855 71.207 14.855L22.332 63.73C20.332 65.73 17.207 65.73 15.207 63.73L10.332 58.855C8.33203 56.855 8.33203 53.73 10.332 51.73L59.207 2.85498ZM20.832 11.23V17.23H26.832C27.832 17.23 28.832 18.23 28.832 19.23C28.832 20.355 27.832 21.23 26.832 21.23H20.832V27.23C20.832 28.355 19.832 29.23 18.832 29.23C17.707 29.23 16.832 28.355 16.832 27.23V21.23H10.832C9.70703 21.23 8.83203 20.355 8.83203 19.23C8.83203 18.23 9.70703 17.23 10.832 17.23H16.832V11.23C16.832 10.23 17.707 9.22998 18.832 9.22998C19.832 9.22998 20.832 10.23 20.832 11.23ZM62.832 41.23C63.832 41.23 64.832 42.23 64.832 43.23V49.23H70.832C71.832 49.23 72.832 50.23 72.832 51.23C72.832 52.355 71.832 53.23 70.832 53.23H64.832V59.23C64.832 60.355 63.832 61.23 62.832 61.23C61.707 61.23 60.832 60.355 60.832 59.23V53.23H54.832C53.707 53.23 52.832 52.355 52.832 51.23C52.832 50.23 53.707 49.23 54.832 49.23H60.832V43.23C60.832 42.23 61.707 41.23 62.832 41.23ZM36.332 3.22998V5.72998H38.832C39.582 5.72998 40.332 6.47998 40.332 7.22998C40.332 8.10498 39.582 8.85498 38.832 8.85498H36.332V11.23C36.332 12.105 35.582 12.855 34.832 12.855C33.957 12.855 33.207 12.105 33.207 11.23V8.85498H30.832C29.957 8.85498 29.207 8.10498 29.207 7.22998C29.207 6.47998 29.957 5.72998 30.832 5.72998H33.207V3.22998C33.207 2.47998 33.957 1.72998 34.832 1.72998C35.582 1.72998 36.332 2.47998 36.332 3.22998Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
