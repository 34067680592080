import Marquee from "react-fast-marquee";
import { ButtonVariant } from "../../button";
import { Layout } from "../../layout";
import { Image } from "../../image";
import { getImage } from "../../../utils";

import "./Presentation.scss";
import { Carousel } from "../../carousel/Carousel";
import { useTranslation } from "react-i18next";
import { Badge, BadgeVariant } from "../../badge";
import { Calendly } from "../../calendly";
import { Yes } from "../../icon";

export function Presentation(): JSX.Element {

    const { t } = useTranslation();

    return (
        <div className="presentation-section">
            <Layout animated={false}>
                <div className="presentation-explanations-container">
                    <p className="presentation-title">
                        {t("presentation.title")}
                    </p>
                    <p className="presentation-description">
                        {t("presentation.description")}
                    </p>
                    <div>
                        <Calendly
                            title={t("presentation.button")}
                            variant={ButtonVariant.PRIMARY}
                        />
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Carousel>
                        <div key={"item1"}>
                            <div className="carousel-item-badge yes">
                                <Yes size={115} />
                            </div>
                            <div className="carousel-item-badge sales-target">
                                <Badge
                                    text={t("badges.sales_goal")}
                                    variant={BadgeVariant.SPECIAL}
                                />
                            </div>
                            <div className="carousel-item-badge cheer-up">
                                <Badge
                                    text={t("badges.support")}
                                    variant={BadgeVariant.SECONDARY}
                                />
                            </div>
                            <div className="carousel-item">
                                <Image
                                    source={getImage("carousel/img1.png")}
                                    // TO DO : Add alt text
                                    alt={""}
                                    draggable={false}
                                />
                            </div>
                        </div>
                        <div key={"item2"}>
                            <div className="carousel-item-badge missions">
                                <Badge
                                    text={t("badges.ai")}
                                    variant={BadgeVariant.SECONDARY}
                                />
                            </div>
                            <div className="carousel-item-badge challenge-1">
                                <Badge
                                    text={t("badges.challenges")}
                                    variant={BadgeVariant.PRIMARY}
                                />
                            </div>
                            <div className="carousel-item">
                                <img
                                    src={getImage("carousel/img2.png")}
                                    alt={"Challenge"}
                                    width={"auto"}
                                    height={474} // same height as the other image. Prevent that all carousel is too high
                                    draggable={false}
                                />
                            </div>
                        </div>
                        <div key={"item3"}>
                            <div className="carousel-item-badge communication">
                                <Badge
                                    text={t("badges.communication")}
                                    variant={BadgeVariant.SPECIAL}
                                />
                            </div>
                            <div className="carousel-item-badge challenge-2">
                                <Badge
                                    text={t("badges.missions")}
                                    variant={BadgeVariant.PRIMARY}
                                />
                            </div>
                            <div className="carousel-item">
                                <Image
                                    source={getImage("carousel/img3.png")}
                                    // TO DO : Add alt text
                                    alt={""}
                                    draggable={false}
                                />
                            </div>
                        </div>
                    </Carousel>
                </div>
            </Layout>
            <Marquee
                autoFill={true}
                speed={20}
                gradient={true}
                gradientWidth={50}
                gradientColor={"#E0E0E0"}
            >
                <div className="marquee-logo">
                    <Image
                        source={getImage("logos/jdb.svg")}
                        alt={"Jeff De Brugges Logo"}
                        title={"Jeff De Brugges"}
                        draggable={false}
                    />
                </div>
                <div className="marquee-logo">
                    <Image
                        source={getImage("logos/kusmitea.svg")}
                        alt={"Kusmi tea Logo"}
                        title={"Kusmi Tea"}
                        draggable={false}
                    />
                </div>
                <div className="marquee-logo">
                    <Image
                        source={getImage("logos/tekoe.png")}
                        alt={"Tekoe Logo"}
                        title={"Tekoe"}
                        draggable={false}
                    />
                </div>
            </Marquee>
        </div>
    );
}
