import { IconProps } from "./interface";

import "./Icons.scss";

export function Close(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1313 15.3125L7.94385 9.07812L1.70947 15.3125C1.42822 15.5938 0.959473 15.5938 0.631348 15.3125C0.350098 14.9844 0.350098 14.5156 0.631348 14.2344L6.86572 8L0.631348 1.8125C0.350098 1.53125 0.350098 1.0625 0.631348 0.734375C0.959473 0.453125 1.42822 0.453125 1.70947 0.734375L7.94385 6.96875L14.1313 0.734375C14.4126 0.453125 14.8813 0.453125 15.2095 0.734375C15.4907 1.0625 15.4907 1.53125 15.2095 1.8125L8.9751 8L15.2095 14.2344C15.4907 14.5156 15.4907 14.9844 15.2095 15.3125C14.8813 15.5938 14.4126 15.5938 14.1313 15.3125Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
