import { Trans, useTranslation } from 'react-i18next';
import { getImage } from '../../../utils';
import { useParameters } from '../useParameters';
import "./NewUser.scss";
import { useEffect } from 'react';
import { APPLE_STORE_LINK, GOOGLEPLAY_STORE_LINK, LINKEDIN_LINK } from '../../../config/constante';
import { Ellipse } from '../../components';
import { getAppLink } from "../utils";

// page / component
// use the function typing
export function NewUserSso(): JSX.Element {
    const parameters = useParameters("authenticatorEnvironment", "environment");
    const { t } = useTranslation();

    const appLink = getAppLink(parameters.environment);

    /**
     * Sets the background color of the body element to '#f6f6f6' when the component mounts.
     * Resets the background color when the component unmounts.
     */
    useEffect(
        () => {
            document.body.style.backgroundColor = '#f6f6f6';
            return () => {
                document.body.style.backgroundColor = '';
            };
        },
        []
    );

    return (
        <table className="mainTable">
            {/* <!-- HEADER --> */}
            <thead>
                <tr>
                    <th colSpan={2} className="ellipse-1">
                        <Ellipse height={120} />
                    </th>
                </tr>
            </thead>
            {/* <!-- END HEADER --> */}
            {/* <!-- MAIN CONTENT --> */}
            <tbody>
                <tr>
                    <td className="img">
                        <a href="https://reachup.app/" target="_blank" rel="noreferrer">
                            <img
                                src={getImage("Logo-min.png")}
                                alt="Reach Up logo"
                                width="209"
                                height="90"
                            />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_title" >
                        <p >
                            {
                                t("Email_newUserSso_title")
                            }
                        </p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} className="email_content new-user_email_content">
                        <p>{t("Email_newUserSso_subTitle")}</p>
                        <p >
                            {
                                t("Email_newUserSso_text")
                            }
                        </p>

                        <Trans
                            i18nKey={"Email_newUserSso_description"}
                            values={{ authenticatorEnvironment: parameters.authenticatorEnvironment }}
                        >
                            <ul
                                className="new-user-sso_description"
                            >
                                <li>
                                    <span>WWW</span>
                                    <a
                                        target="_blank"
                                        className="new-user_to-app-section__link"
                                        href={appLink}
                                    >LINK</a>
                                </li>

                                <li>
                                    XXX
                                    <strong>XXX</strong>
                                </li>
                                <li>
                                    YYY
                                    <strong>YYY</strong>
                                </li>
                                <li>ZZZ</li>
                            </ul>
                        </Trans>
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={2}
                        style={{ padding: 0, paddingTop: "30px" }}
                    >
                        <table className="email_content-table newUser-email_content_table">
                            <tbody>
                                <tr className="new-user_to-app-section__row">
                                    <td colSpan={2} className="new-user_to-app-section__element">
                                        <a
                                            target="_blank"
                                            className="new-user_to-app-section__link"
                                            href={appLink}
                                        >{t("Email_newUserSso_link")}</a>
                                    </td>
                                </tr>
                                <tr className="new-user_to-app-section__row">
                                    <td
                                        colSpan={2}
                                        style={{
                                            paddingTop: 16,
                                        }}
                                        className="new-user_to-app-section__element"
                                    >
                                        <a
                                            target="_blank"
                                            className="new-user_to-app-section__link"
                                            href={appLink}
                                        >{appLink}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: "start" }} >
                                        <a href={APPLE_STORE_LINK} target="_blank" rel="noreferrer">
                                            <img src={getImage("EmailAppleStore.png")} alt="Apple store logo" width={110} height={50} />
                                        </a>
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                        <a href={GOOGLEPLAY_STORE_LINK} target="_blank" rel="noreferrer">
                                            <img src={getImage("EmailGooglePlay.png")} alt="Google Play logo" width={110} height={50} />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ height: 1, textAlign: 'center' }}>
                        <div className="row-to-reduce"></div>
                    </td>
                </tr>
                <tr className="email_content-bottom">
                    <td >
                        <a
                            href="https://reachup.app/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("Logo.png")}
                                alt="Reach Up logo"
                                width="135"
                            />
                        </a>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <a
                            href={LINKEDIN_LINK}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={getImage("linkedin.png")}
                                alt="Linkedin logo"
                                width="21"
                                height="23"
                            />
                        </a>
                    </td>
                </tr>
                {/* <!-- END MAIN CONTENT --> */}
                {/* <!-- FOOTER --> */}
                <tr>
                    <td colSpan={2}>
                        <table className="footer_table">
                            <tbody>
                                <tr>
                                    <td className="footer-value" >
                                        <a
                                            href="https://reachup.app/"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="reachUp-link"
                                        >
                                            {
                                                t("Email__footer_ReachUp")
                                            }
                                        </a>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_adress")} </p>
                                    </td>
                                    <td className="footer-value">&bull;</td>
                                    <td className="footer-value">
                                        <p>{t("Email__footer_city")} </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                {/* <!-- END FOOTER --> */}
            </tbody>
        </table>
    );
};
