import { useCallback, useEffect, useState } from "react";
import { About, Challenges, Comparison, Explanation, Footer, Indicators, NavBar, Objectives, Presentation, Solution, Statement } from "./components";

import "./Main.scss";

export function Main() {
    const [
        statePosition,
        setStatePosition,
    ] = useState<{
        readonly previous: number;
        readonly delta: number;
    }>({
        previous: 0,
        delta: 0,
    });

    const handleScroll = useCallback(
        () => {
            setStatePosition(
                (previousState) => {
                    const current = window.scrollY;

                    // delta is between [0, 105]
                    let nextDelta = previousState.delta + (current - previousState.previous);
                    if (nextDelta <= 0) {
                        nextDelta = 0;
                    }

                    if (nextDelta >= 105) {
                        nextDelta = 105;
                    }

                    return ({
                        delta: nextDelta,
                        previous: current,
                    });
                }
            );
        },
        []
    );

    // add handle for scroll in order to hide / show the navbar
    useEffect(
        () => {
            window.addEventListener("scroll", handleScroll);

            return (
                () => {
                    window.removeEventListener("scroll", handleScroll);
                }
            );
        },
        []
    );


    return (
        <div className="main-container">
            <div
                className="navBar-wrapper"
                // transform Y to statePosition.delta
                style={{
                    transform: `translateY(-${statePosition.delta}px)`
                }}
            >
                <NavBar />
            </div>

            <main>
                <section className="main-section" id="presentation">
                    <Presentation />
                </section>

                <section className="main-section" id="statement">
                    <Statement />
                </section>

                <section className="main-section" id="advantage">
                    <div className="solution-advantage">
                        <Solution />
                        <Explanation />
                    </div>
                </section>

                <section className="main-section">
                    <div className="feature" id="indicator">
                        <Indicators />
                    </div>
                    <div className="feature" id="comparison">
                        <Comparison />
                    </div>
                    <div className="feature" id="objectives">
                        <Objectives />
                    </div>
                    <div className="feature" id="challenges">
                        <Challenges />
                    </div>
                </section>
                <section className="main-section-half" id="about">
                    <About />
                </section>
            </main>
            <div className="footer-wrapper">
                <Footer />
            </div>
        </div>
    );
}
