import "animate.css/animate.compat.css";
import ScrollAnimation from 'react-animate-on-scroll';

import "./Layout.scss";
import { useState } from "react";
export function Layout(props: {
    /**
     * Boolean to know if we should reverse the order of children on large screens.
     * By default, on large screens, first child will be on left and second child will be on right.
     * If reverse is set to true, first child will be on right and second child will be on left.
     * */
    readonly rowReverse?: boolean;
    /** Set to true if you want the childrens to be aligned to their side (left child to the left and right child to the right). Default is false. */
    readonly alignedBySide?: boolean;
    /** Decide to make the layout children animated when enter/exit view */
    readonly animated?: boolean;
    /** Content elements to be displayed in the layout */
    readonly children: [JSX.Element, JSX.Element];
}) {

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    window.addEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
    });

    return (
        <div className={props.rowReverse ? "layout-reverse" : "layout"}>
            {
                props.children.map((child: JSX.Element, index: number): JSX.Element => {

                    let childClassName: string = "layout-child";

                    // 768 is the breakpoint for medium screens (tablets). On smaller screens, we don't need to align children by side.
                    if (props.alignedBySide === true && windowWidth > 768) {
                        if (index === 0) {
                            if (props.rowReverse) {
                                childClassName += " right";
                            } else {
                                childClassName += " left";
                            }
                        } else {
                            if (props.rowReverse) {
                                childClassName += " left";
                            } else {
                                childClassName += " right";
                            }
                        }
                    }

                    if (props.animated === false) {
                        return (
                            <div
                                className="layout-child-wrapper"
                                key={`non-animated-${index}`}
                            >
                                <div className={childClassName}>
                                    {child}
                                </div>
                            </div>
                        );
                    }

                    return (
                        <ScrollAnimation
                            animateIn={
                                index === 0
                                    ? props.rowReverse ? "fadeInRight" : "fadeInLeft"
                                    : props.rowReverse ? "fadeInLeft" : "fadeInRight"
                            }
                            animatePreScroll={true}
                            className="layout-child-wrapper"
                            duration={0.8}
                            key={`animated-${index}`}
                        >
                            <div className={childClassName}>
                                {child}
                            </div>
                        </ScrollAnimation>
                    );
                })
            }
        </div>
    );
}
