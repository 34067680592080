import "./Card.scss";

export function Card(props: {
    readonly title: string;
    readonly description: string;
    readonly textAlignement?: "left" | "center" | "right";
}): JSX.Element {
    return (
        <div className={"card text-left"}>
            <p className={"card-title"}>{props.title}</p>
            <p className={"card-description"}>{props.description}</p>
        </div>
    );
}
