import { useTranslation } from "react-i18next";

import { AppleStore, GoalBadge, GooglePlay, IconColor, LinkedinLogo, LogoReachUp, Up, UpHand } from "../icon";

import "./Footer.scss";
import { Calendly } from "../calendly";
import { ButtonVariant } from "../button";
import { Link } from "react-router-dom";

export function Footer(): JSX.Element {

    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-rows-large">
                <div className="footer-rows-container">
                    <div className="footer-column footer-column-left">
                        <div className="footer-logo-ru">
                            <LogoReachUp
                                size={350}
                                color={IconColor.SECONDARY}
                                highlightColor={IconColor.SECONDARY}
                                shadowColor={IconColor.LOGO_SHADOW_SECONDARY}
                                upColor={IconColor.SPECIAL}
                            />
                        </div>
                        <Calendly title={t("footer.demo_button")} variant={ButtonVariant.SECONDARY} />
                    </div>
                    <div className="footer-column footer-column-right">
                        <p className="footer-title">
                            {t("footer.title")}
                        </p>
                        <a
                            className="footer-link"
                            href="https://www.linkedin.com/company/reach-up-app/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkedinLogo
                                size={50}
                                color={IconColor.PRIMARY}
                                backgroundColor={IconColor.SECONDARY}
                            />
                        </a>
                    </div>
                </div>
                <hr className="footer-separator" />
                <div className="footer-rows-container footer-extra">
                    <div className="footer-download-container">
                        <a
                            className="footer-link"
                            href="https://apps.apple.com/us/app/reach-up-boost-in-store-sales/id6448384603"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AppleStore size={50} />
                        </a>
                        {/**
                     * Google play logo svg is made with a few transparent pixels above and below the logo
                     * so to make it having the same size as the apple store logo we add 1 in its size
                     * */}
                        <a
                            className="footer-link"
                            href="https://play.google.com/store/apps/details?id=com.dgenious.reachup&pli=1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <GooglePlay size={51} />
                        </a>
                    </div>
                    <div className="footer-credits-container">
                        <Link to={"/privacy"}>
                            <p className="footer-privacy">
                                {t("footer.privacy")}
                            </p>
                        </Link>

                        <p className="footer-credits">
                            {t("footer.credits")}
                        </p>
                    </div>
                </div>

                <div className="footer-up-background">
                    <Up color={IconColor.BACKGROUND_UP} />
                </div>
            </div>

            {/* Footer content for small devices */}

            <div className="footer-rows-small">
                <div className="footer-top-part">
                    <p className="footer-title-small">
                        {t("footer.title")}
                    </p>
                    <div>
                        <Calendly title={t("footer.demo_button")} variant={ButtonVariant.SECONDARY} />
                    </div>
                    <div className="footer-goal-badge-wrapper">
                        <GoalBadge size={115} color={IconColor.SPECIAL} lettersColor={IconColor.PRIMARY} />
                    </div>
                    <div className={"footer-contact"}>
                        <LogoReachUp
                            size={220}
                            color={IconColor.SECONDARY}
                            highlightColor={IconColor.SECONDARY}
                            shadowColor={IconColor.LOGO_SHADOW_SECONDARY}
                            upColor={IconColor.SPECIAL}
                        />
                        <div className="linkedin-logo-wrapper">
                            <a
                                className="footer-link linkedin-logo"
                                href="https://www.linkedin.com/company/reach-up-app/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedinLogo
                                    size={50}
                                    color={IconColor.PRIMARY}
                                    backgroundColor={IconColor.SECONDARY}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-separator-wrapper">
                    <hr className="footer-separator" />
                </div>
                <div className="footer-bottom-part">

                    <div className="footer-download">
                        <a
                            className="footer-link"
                            href="https://apps.apple.com/us/app/reach-up-boost-in-store-sales/id6448384603"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <AppleStore size={50} />
                        </a>
                        {/**
                     * Google play logo svg is made with a few transparent pixels above and below the logo
                     * so to make it having the same size as the apple store logo we add 1 in its size
                     * */}
                        <a
                            className="footer-link"
                            href="https://play.google.com/store/apps/details?id=com.dgenious.reachup&pli=1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <GooglePlay size={51} />
                        </a>
                    </div>

                    <div className="footer-credits-container">
                        <Link to={"/privacy"}>
                            <p className="footer-privacy">
                                {t("footer.privacy")}
                            </p>
                        </Link>

                        <p className="footer-credits">
                            {t("footer.credits")}
                        </p>
                    </div>
                </div>

                <div className="footer-up-background-small">
                    <Up color={IconColor.BACKGROUND_UP} />
                </div>
            </div>
        </footer>
    );
}
