import { IconProps } from "./interface";
import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 68 / 81;

export function Clock(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 81 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M68.832 33.7055C68.832 23.7055 63.457 14.5805 54.832 9.58051C46.082 4.45551 35.457 4.45551 26.832 9.58051C18.082 14.5805 12.832 23.7055 12.832 33.7055C12.832 43.8305 18.082 52.9555 26.832 57.9555C35.457 63.0805 46.082 63.0805 54.832 57.9555C63.457 52.9555 68.832 43.8305 68.832 33.7055ZM8.83203 33.7055C8.83203 22.3305 14.832 11.8305 24.832 6.08051C34.707 0.330505 46.832 0.330505 56.832 6.08051C66.707 11.8305 72.832 22.3305 72.832 33.7055C72.832 45.2055 66.707 55.7055 56.832 61.4555C46.832 67.2055 34.707 67.2055 24.832 61.4555C14.832 55.7055 8.83203 45.2055 8.83203 33.7055ZM38.832 15.7055C38.832 14.7055 39.707 13.7055 40.832 13.7055C41.832 13.7055 42.832 14.7055 42.832 15.7055V32.7055L53.832 40.0805C54.832 40.7055 55.082 41.9555 54.457 42.8305C53.832 43.8305 52.582 44.0805 51.707 43.4555L39.707 35.4555C39.082 35.0805 38.832 34.4555 38.832 33.7055V15.7055Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
