const PUBLIC_FOLDER: string | undefined = process.env.PUBLIC_URL;
const IMAGES_FOLDER: string = "images";
const ICONS_FOLDER: string = "icons";
const VIDEOS_FOLDER: string = "video";

export const APPLE_STORE_LINK: string = "https://apps.apple.com/us/app/reach-up-boost-in-store-sales/id6448384603";
export const GOOGLEPLAY_STORE_LINK: string = "https://play.google.com/store/apps/details?id=com.dgenious.reachup";
export const LINKEDIN_LINK: string = "https://www.linkedin.com/company/reach-up-app"

export const getImage = (imageName: string): string => {
    return (`${PUBLIC_FOLDER}/${IMAGES_FOLDER}/${imageName}`);
}
export const getIcon = (iconName: string): string => {
    return (`${PUBLIC_FOLDER}/${ICONS_FOLDER}/${iconName}`);
}
export const getVideo = (videoName: string): string => {
    return (`${PUBLIC_FOLDER}/${VIDEOS_FOLDER}/${videoName}`);
}