import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "../button";
import { Calendly } from "../calendly";
import "./MenuModal.scss";
import { Close, IconColor, LogoReachUp } from "../icon";

export function MenuModal(props: {
    readonly onClose: () => void;
}): JSX.Element {

    const { t } = useTranslation();

    // TO DO : Add Badge - Add Up in background
    return (
        <div className="menu-modal">
            <div className="close-button-container">
                <button
                    className="close-button"
                    onClick={props.onClose}
                >
                    <Close size={24} color={IconColor.PRIMARY} />
                </button>
            </div>
            <div className="nav-buttons-container">
                <button
                    className="menu-modal-nav-button"
                    onClick={() => {
                        window.location.href = "#statement";
                        props.onClose();
                    }}
                >
                    <p className="menu-modal-nav-button-text">{t("navBar.Customer_Testimonial")}</p>
                </button>

                <button
                    className="menu-modal-nav-button"
                    onClick={() => {
                        window.location.href = "#advantage";
                        props.onClose();
                    }}
                >
                    <p className="menu-modal-nav-button-text">{t("navBar.Advantage")}</p>
                </button>

                <button
                    className="menu-modal-nav-button"
                    onClick={() => {
                        window.location.href = "#indicator";
                        props.onClose();
                    }}
                >
                    <p className="menu-modal-nav-button-text">{t("navBar.Features")}</p>
                </button>

                <button
                    className="menu-modal-nav-button"
                    onClick={() => {
                        window.location.href = "#about";
                        props.onClose();
                    }}
                >
                    <p className="menu-modal-nav-button-text">{t("navBar.About")}</p>
                </button>

                <Calendly title={t("navBar.Contact")} variant={ButtonVariant.SECONDARY} />
            </div>
            <div className="logo-container">
                <LogoReachUp
                    color={IconColor.SECONDARY}
                    highlightColor={IconColor.SECONDARY}
                    shadowColor={IconColor.LOGO_SHADOW_SECONDARY}
                    upColor={IconColor.SPECIAL}
                />
            </div>
        </div>
    );
}
