import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "../button";
import { HamburgerMenu, IconColor, LogoReachUp } from "../icon";
import "./NavBar.scss";
import { Calendly } from "../calendly";
import { useEffect, useState } from "react";
import { MenuModal } from "../menuModal";

export function NavBar(): JSX.Element {

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(
        () => {
            if (isModalOpen) {
                document.body.classList.add("no-scroll");
            } else {
                document.body.classList.remove("no-scroll");
            }

            return () => {
                document.body.classList.remove("no-scroll");
            };
        },
        [isModalOpen]
    );

    return (
        <header className="navBar">
            <div className="navBar-action-container">
                <div className="navBar-logo-ru">
                    <LogoReachUp
                        size={120}
                        color={IconColor.PRIMARY}
                        highlightColor={IconColor.SECONDARY}
                        shadowColor={IconColor.LOGO_SHADOW_PRIMARY}
                        upColor={IconColor.SPECIAL}
                    />
                </div>

                <Button
                    variant={ButtonVariant.TEXT}
                    title={t("navBar.Customer_Testimonial")}
                    onClick={() => { window.location.href = "#statement"; }}
                />
                <Button
                    variant={ButtonVariant.TEXT}
                    title={t("navBar.Advantage")}
                    onClick={() => { window.location.href = "#advantage"; }}
                />
                <Button
                    variant={ButtonVariant.TEXT}
                    title={t("navBar.Features")}
                    onClick={() => { window.location.href = "#indicator"; }}
                />
                <Button
                    variant={ButtonVariant.TEXT}
                    title={t("navBar.About")}
                    onClick={() => { window.location.href = "#about"; }}
                />

                <Calendly title={t("navBar.Contact")} />
            </div>

            <div className="navBar-hamburger-menu"
            >
                <div className="navBar-logo-ru">
                    <LogoReachUp
                        size={120}
                        color={IconColor.PRIMARY}
                        highlightColor={IconColor.SECONDARY}
                        shadowColor={IconColor.LOGO_SHADOW_PRIMARY}
                        upColor={IconColor.SPECIAL}
                    />
                </div>

                <button
                    onClick={() => { setIsModalOpen(true); }}
                >
                    <HamburgerMenu size={30} color={IconColor.PRIMARY} />
                </button>
            </div>

            {
                isModalOpen && <MenuModal onClose={() => { setIsModalOpen(false); }} />
            }
        </header>
    );
}
