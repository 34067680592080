import "./Button.scss";

export enum ButtonVariant {
    OUTLINED = "outlined",
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TEXT = "text",
}

const DEFAULT_VARIANT = ButtonVariant.PRIMARY as const;

export function Button(props: {
    readonly title?: string;
    readonly variant?: ButtonVariant;
    readonly onClick?: () => void;
}): JSX.Element {
    return (
        <button onClick={props.onClick} className={`button-${props.variant ?? DEFAULT_VARIANT}`}>
            <p>
                {props.title ?? ""}
            </p>
        </button>
    );
}
