import { useTranslation } from "react-i18next";
import { Calendly } from "../../calendly";
import "./About.scss";
import ScrollAnimation from "react-animate-on-scroll";

export function About(): JSX.Element {

    const { t } = useTranslation();

    return (
        <ScrollAnimation
            animateIn={"fadeIn"}
            animatePreScroll={true}
            className="about-container"
            duration={0.8}
        >
            <div className="about-wrapper">
                <p className="about-title">
                    {t("about.title")}
                </p>

                <p className="about-description">{t("about.description")}</p>

                <p className="about-description">{t("about.start_bullet_point")}</p>

                <ul>
                    <li className="about-description">{t("about.bullet_1")}</li>
                    <li className="about-description">{t("about.bullet_2")}</li>
                    <li className="about-description">{t("about.bullet_3")}</li>
                    <li className="about-description">{t("about.bullet_4")}</li>
                </ul>

                <p className="about-description">{t("about.after_bullet_point")}</p>

            </div>

            <div className="about-wrapper" id="demo">
                <p className="about-title blue">
                    {t("about.cta_title")}
                </p>

                <Calendly
                    takeAllSpace
                    title={t("about.cta_button")}
                />
            </div>
        </ScrollAnimation>
    );
}
