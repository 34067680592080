export enum IconColor {
    BACKGROUND_UP = "background-up",
    LIGHT_GREY = "light-grey",
    LOGO_SHADOW_PRIMARY = "logo-shadow-primary",
    LOGO_SHADOW_SECONDARY = "logo-shadow-secondary",
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SPECIAL = "special",
}

export interface IconProps {
    size: number;
    color: IconColor;
}