import { PopupButton } from 'react-calendly';

import { ButtonVariant } from '../button';

import "./Calendly.scss";

interface PropsCalendlyBtn {
    variant?: ButtonVariant;
    title: string;
    readonly takeAllSpace?: boolean;
}

export function Calendly(props: PropsCalendlyBtn): JSX.Element {
    return (
        <PopupButton
            className={`calendly-button ${props.variant} ${props.takeAllSpace ? "take-all-space" : ""}`}
            url="https://calendly.com/mvanlierde/decouverte-reach-up-avec-martin"
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root") as HTMLElement}
            text={props.title}
        />
    );
};
