import { IconProps } from "./interface";

import "./Icons.scss";

export function Up(props: Omit<IconProps, "size">): JSX.Element {
    return (
        <svg width={"100%"} height={"auto"} viewBox="0 0 828 509" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M823.359 128.876C798.775 23.513 725.892 -17.493 627.924 8.45342C583.201 20.3732 555.627 28.5928 515.744 49.5937C496.034 40.3721 479.349 43.2732 460.09 48.7742C445.448 52.6526 431.666 59.246 419.458 68.2116C449.112 105.279 465.053 168.586 467.967 260.646C469.776 312.59 469.215 366.086 456.279 413.39C478.847 474.05 513.168 515.88 576.842 499.875C618.068 489.481 654.602 475.786 655.29 427.246C655.557 402.652 648.535 372.197 638.478 339.896C751.73 321.051 847.616 233.073 823.339 128.883M599.603 221.335C598.533 217.07 597.618 213.454 596.699 209.802L590.172 186.304C589.253 182.652 588.188 178.423 587.269 174.771C676.111 140.815 681.354 200.094 599.613 221.334M755.978 90.991C751.281 88.0174 747.085 84.3186 743.546 80.0322C734.682 70.1635 723.701 62.4269 711.425 57.4011C698.342 52.0637 684.346 43.7375 691.137 34.004C692.554 32.0538 694.439 30.4923 696.619 29.4639C698.799 28.4356 701.203 27.9737 703.609 28.1209C712.613 28.787 721.379 31.3206 729.35 35.5604C737.321 39.8002 744.321 45.6533 749.906 52.7471C771.672 79.3198 765.278 95.7565 755.978 90.991Z"
                className={`fill-${props.color}`}
            />
            <path
                d="M366.426 44.8525C338.158 13.3818 306.717 15.3786 281.707 20.8387L281.402 20.9058C239.253 30.1688 224.067 62.9632 220.98 107.17C219.341 137.645 248.689 273.966 255.652 311.529C256.397 314.158 256.597 316.912 256.238 319.621C255.879 322.331 254.97 324.938 253.567 327.283C252.163 329.628 250.294 331.661 248.076 333.257C245.857 334.853 243.335 335.978 240.666 336.563L240.64 336.566C237.971 337.145 235.211 337.173 232.53 336.649C229.849 336.124 227.304 335.058 225.049 333.516C222.795 331.973 220.879 329.986 219.42 327.678C217.96 325.369 216.987 322.786 216.56 320.088C205.828 271.215 187.907 160.536 171.641 124.433C151.942 81.4252 126.341 55.0381 82.4348 64.6188L82.2806 64.6344C-14.252 85.8968 -11.7829 164.12 21.2399 299.644C49.5023 419.59 99.787 534.689 263.95 503.719C267.922 502.98 271.78 502.201 275.602 501.368C406.091 472.88 436.459 396.154 434.119 301.101C432.943 270.449 429.804 239.905 424.72 209.655C411.143 120.178 389.867 70.955 366.429 44.8314M387.895 355.168C387.893 355.201 387.896 355.234 387.905 355.266C382.326 410.639 347.673 431.957 347.673 431.957C331.626 441.573 333.004 424.725 344.206 407.927C352.423 395.575 356.349 373.817 357.747 356.76C359.703 333.323 388.087 331.671 387.939 355.195"
                className={`fill-${props.color}`}
            />
        </svg>

    );
}
