import { useCallback, useRef, useState } from "react";
import { Chevron } from "../icon";

import "./Accordion.scss";
import { IconColor } from "../icon/interface";

export function Accordion(props: {
    readonly title: string;
    readonly children: JSX.Element;
    readonly openByDefault?: boolean;
}): JSX.Element {
    const openByDefault = props.openByDefault ?? false;

    const [isOpen, setIsOpen] = useState(openByDefault);

    const toggleAccordion = useCallback(
        () => {
            setIsOpen((previousState: boolean): boolean => !previousState);
        },
        []
    );

    return (
        <div
            className="accordion"
            style={{
                cursor: "pointer",
            }}
            onClick={toggleAccordion}
        >
            <div className="accordion-header">
                <p className="accordion-title">
                    {props.title}
                </p>
                <Chevron
                    color={IconColor.PRIMARY}
                    rotationAngle={isOpen ? 180 : 0}
                    size={20}
                />
            </div>

            {
                isOpen && <div className="accordion-content">
                    {props.children}
                </div>
            }
        </div>
    );
}
