import { IconProps } from "./interface";

import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 11 / 21;

export function Chevron(props: {
    readonly rotationAngle?: number;
} & IconProps) {
    const usedRotationAngle = props.rotationAngle ?? 0;
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ transform: `rotate(${usedRotationAngle}deg)` }}
        >
            <path
                d="M10.7103 10.725C10.4122 11.0231 9.92178 11.0231 9.62367 10.725L0.392036 1.49332C0.0939308 1.19522 0.0939308 0.704789 0.392036 0.406684C0.690141 0.108579 1.18057 0.108579 1.47868 0.406684L10.167 9.095L18.8553 0.406684C19.1534 0.108579 19.6438 0.108579 19.9419 0.406684C20.2401 0.704789 20.2401 1.19522 19.9419 1.49332L10.7103 10.725Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
