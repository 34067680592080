import { IconProps } from './interface';

import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 65 / 80;

export function Trophy(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 80 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26 4.22998C24.875 4.22998 24 5.22998 24 6.10498C24.875 26.605 29.625 36.105 33.5 40.355C35.5 42.48 37.25 43.48 38.5 43.855C39 44.105 39.5 44.23 39.75 44.23C39.875 44.23 39.875 44.23 39.875 44.23C40 44.23 40 44.23 40.125 44.23C40.375 44.23 40.75 44.105 41.375 43.855C42.625 43.48 44.375 42.48 46.375 40.355C50.25 36.105 55 26.605 55.875 6.10498C55.875 5.22998 55.125 4.22998 54 4.22998H26ZM54 0.22998C57.25 0.22998 60 2.97998 59.875 6.35498C59.75 6.97998 59.75 7.60498 59.75 8.22998H70C73.25 8.22998 76.125 10.98 75.75 14.48C74.5 26.23 68 34.23 61 39.48C54 44.73 46.375 47.105 42.25 47.855C42.125 47.855 42 47.855 42 47.98V60.23H54C55 60.23 56 61.23 56 62.23C56 63.355 55 64.23 54 64.23H40H26C24.875 64.23 24 63.355 24 62.23C24 61.23 24.875 60.23 26 60.23H38V47.855C37.875 47.855 37.75 47.855 37.625 47.855C33.625 47.105 25.875 44.605 18.875 39.48C11.875 34.23 5.375 26.23 4.125 14.48C3.75 10.98 6.75 8.22998 10 8.22998H20.125C20.125 7.60498 20.125 6.97998 20 6.35498C19.875 2.97998 22.625 0.22998 26 0.22998H54ZM59.5 12.23C58.25 26.855 54.875 35.48 51.125 40.73C53.625 39.605 56.125 38.105 58.625 36.23C65 31.605 70.625 24.48 71.75 14.105C71.875 13.23 71.125 12.23 70 12.23H59.5ZM28.75 40.73C25.125 35.48 21.625 26.855 20.375 12.23H10C8.75 12.23 8 13.23 8.125 14.105C9.25 24.48 14.875 31.605 21.375 36.23C23.75 38.105 26.375 39.605 28.75 40.73Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
