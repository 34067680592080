import MultiCarousel from 'react-multi-carousel';

import "./Carousel.scss";
import "react-multi-carousel/lib/styles.css";
import { ReactNode } from 'react';

const AUTOPLAY_SPEED = 5000 as const;

function CustomDot({ onClick, ...rest }: { onClick: any, [key: string]: any; }): JSX.Element {
    const { active } = rest;

    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
        <button
            className={active ? "carousel-indicator active" : "carousel-indicator inactive"}
            onClick={(e) => onClick(e)}
        />
    );
};

export function Carousel(props: {
    readonly children: ReactNode;
}): JSX.Element {
    return (
        <MultiCarousel
            // Hide arrows
            arrows={false}

            // Make the carousel play by itself
            autoPlay={true}
            autoPlaySpeed={AUTOPLAY_SPEED}
            shouldResetAutoplay={true}
            infinite={true}
            // Pause the carousel when hovering over it
            pauseOnHover={true}

            // To stylize the carousel
            containerClass="carousel-container"

            // Show custom navigation dots
            showDots={true}
            customDot={
                // The empty onClick function is required by the library
                // Even if we don't use it because the library handles the navigation.
                <CustomDot onClick={() => { }} />
            }
            dotListClass="carousel-indicator-container"
            renderButtonGroupOutside={true}
            renderDotsOutside={false}

            // Make the carousel draggable and swipeable
            draggable={true}
            minimumTouchDrag={80}
            swipeable={true}

            responsive={{
                //make that any format takes 1 item to display
                any: {
                    breakpoint: { max: 99999, min: 0 },
                    items: 1,
                }
            }}
        >
            {props.children}
        </MultiCarousel>
    );
}
