import "./Privacy.scss";
import { Link } from "react-router-dom";
import { getImage } from "../utils";

export const Privacy = () => {
    return (
        <main className="Privacy">
            <div className="nav__privacy">
                <img src={getImage("Logo.png")} alt="Logo Reach Up" />
                <Link to={".."} className="back__to__site">
                    <svg
                        width="23"
                        height="20"
                        viewBox="0 0 23 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1.21765 10.8235C0.985289 10.5887 0.851807 10.2723 0.851807 9.93549C0.851807 9.59867 0.985289 9.28227 1.21765 9.04751L9.9187 0.473923C10.3933 0.00441728 11.1448 0.0248307 11.5946 0.51475C12.0445 1.00467 12.0297 1.78038 11.5551 2.24478L4.99964 8.71069H21.8134C22.471 8.71069 23 9.25675 23 9.93549C23 10.6142 22.471 11.1603 21.8134 11.1603H4.99964L11.56 17.6211C12.0346 18.0906 12.0495 18.8612 11.5996 19.3511C11.1497 19.841 10.3983 19.8564 9.92365 19.392L1.22259 10.8184L1.21765 10.8235Z"
                            fill="black"
                        />
                    </svg>
                    Go back to the web site.
                </Link>
            </div>

            <div className="privacy__content">
                <div className="introduction">
                    <h1>PRIVACY POLICY</h1>
                    <span>Last updated June 27, 2023</span>
                    <div className="introduction__content container">
                        <p>
                            This privacy notice for dgenious SA (<strong>'Company'</strong>,{" "}
                            <strong>'we'</strong>, <strong>'us'</strong>, or{" "}
                            <strong>'our'</strong>), describes how and why we might collect,
                            store, use, and/or share <strong>('process')</strong> your
                            information when you use our services ('Services'), such as when
                            you:
                        </p>
                        <ul>
                            <li>
                                Download and use our mobile application (Reach Up), or any
                                other application of ours that links to this privacy notice.
                            </li>
                            <li>
                                Engage with us in other related ways, including any sales,
                                marketing, or events.
                            </li>
                        </ul>
                        <p>
                            <strong>Questions or concerns?</strong> Reading this privacy
                            notice will help you understand your privacy rights and choices.
                            If you do not agree with our policies and practices, please do
                            not use our Services. If you still have any questions or
                            concerns, please contact us at contact@reachup.app .
                        </p>
                    </div>
                </div>
                {/* Summary */}
                <div className="summary__content container">
                    <h2>SUMMARY OF KEY POINTS</h2>
                    <p>
                        This summary provides key points from our privacy notice, but you
                        can find out more details about any of these topics by clicking
                        the link following each key point or by using our{" "}
                        <a href={"#table-of-content"}>table of contents </a> below to find
                        the section you are looking for.
                    </p>
                    <p>
                        <strong>What personal information do we process?</strong> When you
                        visit, use, or navigate our Services, we may process personal
                        information depending on how you interact with dgenious SA and the
                        Services, the choices you make, and the products and features you
                        use. Learn more about{" "}
                        <a href="#personal-information" className="underline__link">
                            personal information you disclose to us.
                        </a>
                    </p>
                    <p>
                        <strong>Do we process any sensitive personal information?</strong>{" "}
                        We do not process sensitive personal information.
                    </p>
                    <p>
                        <strong>Do we receive any information from third partie?</strong>
                        We do not receive any information from third parties.
                    </p>
                    <p>
                        <strong> How do we process your information?</strong> We process
                        your information to provide, improve, and administer our Services,
                        communicate with you, for security and fraud prevention, and to
                        comply with law. We may also process your information for other
                        purposes with your consent. We process your information only when
                        we have a valid legal reason to do so. Learn more about{" "}
                        <a href="#how-do-we-proccess" className="underline__link">
                            how we process your information.
                        </a>
                    </p>
                    <p>
                        <strong>
                            In what situations and with which parties do we share personal
                            information?
                        </strong>{" "}
                        We may share information in specific situations and with specific
                        third parties.{" "}
                        <a href="#personal-information" className="underline__link">
                            Learn more about when and with whom we share your personal
                            information.
                        </a>
                    </p>
                    <p>
                        <strong>What are your rights?</strong> Depending on where you are
                        located geographically, the applicable privacy law may mean you
                        have certain rights regarding your personal information. Learn
                        more about{" "}
                        <a href="#what-are-your-privacy-right" className="underline__link">
                            your privacy rights.
                        </a>
                    </p>
                    <p>
                        <strong>How do you exercise your rights?</strong> The easiest way
                        to exercise your rights is by visiting{" "}
                        <Link to={".."}>https://reachup.app</Link>, or by contacting us.
                        We will consider and act upon any request in accordance with
                        applicable data protection laws.
                    </p>
                    <p>
                        Want to learn more about what dgenious SA does with any
                        information we collect?{" "}
                        <a href="#table-of-content" className="underline__link">
                            Review the privacy notice in full.
                        </a>
                    </p>
                </div>
                {/*  Table of content */}
                <div
                    className="tableOfContent__content container"
                    id="table-of-content"
                >
                    <h2>TABLE OF CONTENTS</h2>
                    <ul>
                        <li>
                            <a href="#information-about-collect">
                                WHAT INFORMATION DO WE COLLECT?
                            </a>
                        </li>
                        <li>
                            <a href="#how-do-we-proccess">
                                HOW DO WE PROCESS YOUR INFORMATION?
                            </a>
                        </li>
                        <li>
                            <a href="#what-legal-bases">
                                WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                                INFORMATION?
                            </a>
                        </li>
                        <li className="underline__link">
                            <a href="#personal-information">
                                WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                            </a>
                        </li>
                        <li>
                            <a href="#cookies">
                                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                            </a>
                        </li>
                        <li>
                            <a href="#how-long-we-keep-your-informations">
                                HOW LONG DO WE KEEP YOUR INFORMATION?
                            </a>
                        </li>
                        <li>
                            <a href="#collect-information-from-minors">
                                DO WE COLLECT INFORMATION FROM MINORS?
                            </a>
                        </li>
                        <li className="underline__link">
                            <a href="#what-are-your-privacy-right">
                                WHAT ARE YOUR PRIVACY RIGHTS?
                            </a>
                        </li>
                        <li>
                            <a href="#controls-for-do-not-track-features">
                                CONTROLS FOR DO-NOT-TRACK FEATURES
                            </a>
                        </li>
                        <li>
                            <a href="#do-california-residents-have-specific-privacy-right">
                                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                            </a>
                        </li>
                        <li>
                            <a href="#do-we-make-updates-to-this-notice">
                                DO WE MAKE UPDATES TO THIS NOTICE?
                            </a>
                        </li>
                        <li>
                            <a href="#contact-us">
                                HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </a>
                        </li>
                        <li>
                            <a href="#how-can-you-review-data-we-collect-from-you">
                                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                                YOU?
                            </a>
                        </li>
                    </ul>
                </div>
                {/* information about collect */}
                <div className="chapter__1 container" id="information-about-collect">
                    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                    <h3>Personal information you disclose to us</h3>
                    <p>
                        <strong>In Short:</strong>We collect personal information that you
                        provide to us.
                    </p>
                    <p>
                        We collect personal information that you voluntarily provide to us
                        when you express an interest in obtaining information about us or
                        our products and Services, when you participate in activities on
                        the Services, or otherwise when you contact us.
                    </p>
                    <p>
                        <strong>Personal Information Provided by You.</strong>The personal
                        information that we collect depends on the context of your
                        interactions with us and the Services, the choices you make, and
                        the products and features you use. The personal information we
                        collect may include the following:
                    </p>
                    <ul>
                        <li>passwords</li>
                        <li>email addresses</li>
                    </ul>
                    <p>
                        <strong>Sensitive Information.</strong> We do not process
                        sensitive information.
                    </p>
                    <p>
                        <strong>Application Data.</strong>If you use our application(s),
                        we also may collect the following information if you choose to
                        provide us with access or permission:
                    </p>
                    <ul>
                        <li>
                            Mobile Device Data. We automatically collect device information
                            (such as your mobile device ID, model, and manufacturer),
                            operating system, version information and system configuration
                            information, device and application identification numbers,
                            browser type and version, hardware model Internet service
                            provider and/or mobile carrier, and Internet Protocol (IP)
                            address (or proxy server). If you are using our application(s),
                            we may also collect information about the phone network
                            associated with your mobile device, your mobile device’s
                            operating system or platform, the type of mobile device you use,
                            your mobile device’s unique device ID, and information about the
                            features of our application(s) you accessed.
                        </li>
                        <li>
                            Push Notifications. We may request to send you push
                            notifications regarding your account or certain features of the
                            application(s). If you wish to opt out from receiving these
                            types of communications, you may turn them off in your device's
                            settings.
                        </li>
                    </ul>
                    <p>
                        This information is primarily needed to maintain the security and
                        operation of our application(s), for troubleshooting, and for our
                        internal analytics and reporting purposes.
                    </p>
                    <p>
                        All personal information that you provide to us must be true,
                        complete, and accurate, and you must notify us of any changes to
                        such personal information.
                    </p>
                    <h3>Information automatically collected</h3>
                    <p>
                        <strong>In Short:</strong>Some information — such as your Internet
                        Protocol (IP) address and/or browser and device characteristics —
                        is collected automatically when you visit our Services.
                    </p>
                    <p>
                        We automatically collect certain information when you visit, use,
                        or navigate the Services. This information does not reveal your
                        specific identity (like your name or contact information) but may
                        include device and usage information, such as your IP address,
                        browser and device characteristics, operating system, language
                        preferences, referring URLs, device name, country, location,
                        information about how and when you use our Services, and other
                        technical information. This information is primarily needed to
                        maintain the security and operation of our Services, and for our
                        internal analytics and reporting purposes
                    </p>
                    <p>
                        Like many businesses, we also collect information through cookies
                        and similar technologies.
                    </p>
                    <p>The information we collect includes:</p>
                    <ul>
                        <li>
                            Device Data. We collect device data such as information about
                            your computer, phone, tablet, or other device you use to access
                            the Services. Depending on the device used, this device data may
                            include information such as your IP address (or proxy server),
                            device and application identification numbers, location, browser
                            type, hardware model, Internet service provider and/or mobile
                            carrier, operating system, and system configuration information.
                        </li>
                    </ul>
                </div>

                {/* how do we proccess */}
                <div className="chapter__2 container" id="how-do-we-proccess">
                    <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p>
                        <strong>In Short:</strong>We process your information to provide,
                        improve, and administer our Services, communicate with you, for
                        security and fraud prevention, and to comply with law. We may also
                        process your information for other purposes with your consent.
                    </p>
                    <p>
                        <strong>
                            We process your personal information for a variety of reasons,
                            depending on how you interact with our Services, including:
                        </strong>
                    </p>
                    <ul>
                        <li>
                            <strong>
                                To deliver and facilitate delivery of services to the user.
                            </strong>{" "}
                            We may process your information to provide you with the
                            requested service.
                        </li>
                        <li>
                            <strong>
                                To respond to user inquiries/offer support to users.
                            </strong>{" "}
                            We may process your information to respond to your inquiries and
                            solve any potential issues you might have with the requested
                            service.
                        </li>
                        <li>
                            <strong>To request feedback.</strong>We may process your
                            information when necessary to request feedback and to contact
                            you about your use of our Services.
                        </li>
                        <li>
                            <strong>To identify usage trends.</strong>We may process
                            information about how you use our Services to better understand
                            how they are being used so we can improve them.
                        </li>
                        <li>
                            <strong>
                                To save or protect an individual's vital interest.
                            </strong>
                            We may process your information when necessary to save or
                            protect an individual’s vital interest, such as to prevent harm.
                        </li>
                    </ul>
                </div>
                {/*
        What legal bases
        */}
                <div className="chapter__3 container" id="what-legal-bases">
                    <h2>
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                    </h2>
                    <p>
                        <strong>In Short:</strong> We only process your personal
                        information when we believe it is necessary and we have a valid
                        legal reason (i.e. legal basis) to do so under applicable law,
                        like with your consent, to comply with laws, to provide you with
                        services to enter into or fulfil our contractual obligations, to
                        protect your rights, or to fulfil our legitimate business
                        interests.
                    </p>
                    <p>
                        The General Data Protection Regulation (GDPR) and UK GDPR require
                        us to explain the valid legal bases we rely on in order to process
                        your personal information. As such, we may rely on the following
                        legal bases to process your personal information:
                    </p>
                    <ul>
                        <li>
                            <strong>Consent.</strong>We may process your information if you
                            have given us permission (i.e. consent) to use your personal
                            information for a specific purpose. You can withdraw your
                            consent at any time. Learn more about{" "}
                            <a href="/" className="underline__link">
                                withdrawing your consent.
                            </a>
                        </li>
                        <li>
                            <strong>Performance of a Contract.</strong> We may process your
                            personal information when we believe it is necessary
                            to fulfil our contractual obligations to you, including
                            providing our Services or at your request prior to entering into
                            a contract with you.
                        </li>
                        <li>
                            <strong>Legitimate Interests.</strong>We may process your
                            information when we believe it is reasonably necessary to
                            achieve our legitimate business interests and those interests do
                            not outweigh your interests and fundamental rights and freedoms.
                            For example, we may process your personal information for some
                            of the purposes described in order to:
                            <ul>
                                <li>
                                    Analyse how our Services are used so we can improve them to
                                    engage and retain users
                                </li>
                                <li>
                                    Understand how our users use our products and services so we
                                    can improve user experience
                                </li>
                            </ul>
                        </li>
                        <li>
                            <strong>Legal Obligations.</strong>We may process your
                            information where we believe it is necessary for compliance with
                            our legal obligations, such as to cooperate with a law
                            enforcement body or regulatory agency, exercise or defend our
                            legal rights, or disclose your information as evidence in
                            litigation in which we are involved.
                        </li>
                        <li>
                            <strong>Vital Interests.</strong>We may process your information
                            where we believe it is necessary to protect your vital interests
                            or the vital interests of a third party, such as situations
                            involving potential threats to the safety of any person.
                        </li>
                    </ul>
                </div>
                {/*
         Personal information
         */}
                <div className="chapter__4 container" id="personal-information">
                    <h2>
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </h2>
                    <p>
                        <strong>In Short:</strong>We may share information in specific
                        situations described in this section and/or with the following
                        third parties.
                    </p>
                    <p>
                        We may need to share your personal information in the following
                        situations:
                    </p>
                    <ul>
                        <li>
                            <strong>Business Transfers.</strong> We may share or transfer
                            your information in connection with, or during negotiations of,
                            any merger, sale of company assets, financing, or acquisition of
                            all or a portion of our business to another company.
                        </li>
                    </ul>
                </div>
                {/*
        Cookies
         */}
                <div className="chapter__5 container" id="cookies">
                    <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p>
                        <strong>In Short:</strong> We may use cookies and other tracking
                        technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies (like web
                        beacons and pixels) to access or store information. Specific
                        information about how we use such technologies and how you can
                        refuse certain cookies is set out in our Cookie Notice.
                    </p>
                </div>
                {/*
        how long we keep your informations
        */}
                <div
                    className="chapter__6 container"
                    id="how-long-we-keep-your-informations"
                >
                    <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p>
                        <strong>In Short:</strong>We keep your information for as long as
                        necessary to fulfil the purposes outlined in this privacy notice
                        unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long as it is
                        necessary for the purposes set out in this privacy notice, unless
                        a longer retention period is required or permitted by law (such as
                        tax, accounting, or other legal requirements).
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to process your
                        personal information, we will either delete or anonymise such
                        information, or, if this is not possible (for example, because
                        your personal information has been stored in backup archives),
                        then we will securely store your personal information and isolate
                        it from any further processing until deletion is possible.
                    </p>
                </div>
                {/*
        DO WE COLLECT INFORMATION FROM MINORS?
         */}
                <div
                    className="chapter__7 container"
                    id="collect-information-from-minors"
                >
                    <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                    <p>
                        <strong>In Short:</strong>We do not knowingly collect data from or
                        market to children under 18 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to children under
                        18 years of age. By using the Services, you represent that you are
                        at least 18 or that you are the parent or guardian of such a minor
                        and consent to such minor dependent’s use of the Services. If we
                        learn that personal information from users less than 18 years of
                        age has been collected, we will deactivate the account and take
                        reasonable measures to promptly delete such data from our records.
                        If you become aware of any data we may have collected from
                        children under age 18, please contact us at contact@reachup.app.
                    </p>
                </div>
                {/*
        WHAT ARE YOUR PRIVACY RIGHTS?
        */}
                <div
                    className="chapter__8 container"
                    id="what-are-your-privacy-right"
                >
                    <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                    <p>
                        <strong>In Short:</strong>In some regions, such as the European
                        Economic Area (EEA) and United Kingdom (UK), you have rights that
                        allow you greater access to and control over your personal
                        information. You may review, change, or terminate your account at
                        any time.
                    </p>
                    <p>
                        In some regions (like the EEA and UK), you have certain rights
                        under applicable data protection laws. These may include the right
                        (i) to request access and obtain a copy of your personal
                        information, (ii) to request rectification or erasure; (iii) to
                        restrict the processing of your personal information; and (iv) if
                        applicable, to data portability. In certain circumstances, you may
                        also have the right to object to the processing of your personal
                        information. You can make such a request by contacting us by using
                        the contact details provided in the section{" "}
                        <a href="#contact-us" className="underline__link">
                            'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
                        </a>{" "}
                        below.
                    </p>
                    <p>
                        We will consider and act upon any request in accordance with
                        applicable data protection laws.
                    </p>
                    <p>
                        If you are located in the EEA or UK and you believe we are
                        unlawfully processing your personal information, you also have the
                        right to complain to your{" "}
                        <a href="/" className="underline__link">
                            Member State data protection authority
                        </a>{" "}
                        or{" "}
                        <a href="/" className="underline__link">
                            UK data protection authority.
                        </a>
                    </p>
                    <p>
                        If you are located in Switzerland, you may contact the{" "}
                        <a href="/" className="underline__link">
                            Federal Data Protection and Information Commissioner.
                        </a>
                    </p>
                    <p>
                        <strong>
                            <u>Withdrawing your consent:</u>
                        </strong>
                        If we are relying on your consent to process your personal
                        information, you have the right to withdraw your consent at any
                        time. You can withdraw your consent at any time by contacting us
                        by using the contact details provided in the section{" "}
                        <a href="#contact-us" className="underline__link">
                            'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'
                        </a>{" "}
                        below.
                    </p>
                    <p>
                        However, please note that this will not affect the lawfulness of
                        the processing before its withdrawal nor, will it affect the
                        processing of your personal information conducted in reliance on
                        lawful processing grounds other than consent.
                    </p>
                    <p>
                        <strong>
                            <u>Cookies and similar technologies:</u>
                        </strong>
                        Most Web browsers are set to accept cookies by default. If you
                        prefer, you can usually choose to set your browser to remove
                        cookies and to reject cookies. If you choose to remove cookies or
                        reject cookies, this could affect certain features or services of
                        our Services. You may also{" "}
                        <a href="/" className="underline__link">
                            opt out of interest-based advertising by advertisers
                        </a>{" "}
                        on our Services.
                    </p>
                </div>
                {/* CONTROLS FOR DO-NOT-TRACK FEATURES */}
                <div
                    className="chapter__9 container"
                    id="controls-for-do-not-track-features"
                >
                    <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p>
                        Most web browsers and some mobile operating systems and mobile
                        applications include a Do-Not-Track ('DNT') feature or setting you
                        can activate to signal your privacy preference not to have data
                        about your online browsing activities monitored and collected. At
                        this stage no uniform technology standard for recognising and
                        implementing DNT signals has been finalised. As such, we do not
                        currently respond to DNT browser signals or any other mechanism
                        that automatically communicates your choice not to be tracked
                        online. If a standard for online tracking is adopted that we must
                        follow in the future, we will inform you about that practice in a
                        revised version of this privacy notice.
                    </p>
                </div>
                {/* DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS? */}
                <div
                    className="chapter__10 container"
                    id="do-california-residents-have-specific-privacy-right"
                >
                    <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                    <p>
                        <strong>In Short:</strong>Yes, if you are a resident of
                        California, you are granted specific rights regarding access to
                        your personal information.
                    </p>
                    <p>
                        California Civil Code Section 1798.83, also known as the 'Shine
                        The Light' law, permits our users who are California residents to
                        request and obtain from us, once a year and free of charge,
                        information about categories of personal information (if any) we
                        disclosed to third parties for direct marketing purposes and the
                        names and addresses of all third parties with which we shared
                        personal information in the immediately preceding calendar year.
                        If you are a California resident and would like to make such a
                        request, please submit your request in writing to us using the
                        contact information provided below.
                    </p>
                    <p>
                        If you are under 18 years of age, reside in California, and have a
                        registered account with Services, you have the right to request
                        removal of unwanted data that you publicly post on the Services.
                        To request removal of such data, please contact us using the
                        contact information provided below and include the email address
                        associated with your account and a statement that you reside in
                        California. We will make sure the data is not publicly displayed
                        on the Services, but please be aware that the data may not be
                        completely or comprehensively removed from all our systems (e.g.
                        backups, etc.).
                    </p>
                </div>
                {/* DO WE MAKE UPDATES TO THIS NOTICE? */}
                <div
                    className="chapter__11 container"
                    id="do-we-make-updates-to-this-notice"
                >
                    <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                    <p>
                        <strong>In Short:</strong>Yes, we will update this notice as
                        necessary to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy notice from time to time. The updated
                        version will be indicated by an updated 'Revised' date and the
                        updated version will be effective as soon as it is accessible. If
                        we make material changes to this privacy notice, we may notify you
                        either by prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to review
                        this privacy notice frequently to be informed of how we are
                        protecting your information.
                    </p>
                </div>
                {/* HOW CAN YOU CONTACT US ABOUT THIS NOTICE? */}
                <div className="chapter__12 container" id="contact-us">
                    <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p>
                        If you have questions or comments about this notice, you may email
                        us at contact@reachup.app or contact us by post at:
                    </p>
                    <ul>
                        <li>dgenious SA</li>
                        <li>Avenue Louise 231</li>
                        <li>Brussels 1050</li>
                        <li>Belgium</li>
                    </ul>
                </div>
                {/* HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU? */}
                <div
                    className="chapter__13 container"
                    id="how-can-you-review-data-we-collect-from-you"
                >
                    <h2>
                        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                        YOU?
                    </h2>
                    <p>
                        Based on the applicable laws of your country, you may have the
                        right to request access to the personal information we collect
                        from you, change that information, or delete it. To request to
                        review, update, or delete your personal information, please visit:{" "}
                        <Link to={".."}>https://reachup.app</Link> or refer to{" "}
                        <a href="#contact-us">
                            section 12
                        </a>.
                    </p>
                </div>
            </div>
        </main>
    );
};
