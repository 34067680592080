import { IconProps } from './interface';

import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 17 / 22;

export function HamburgerMenu(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.443848 0.75C0.443848 0.375 0.771973 0 1.19385 0H20.6938C21.0688 0 21.4438 0.375 21.4438 0.75C21.4438 1.17188 21.0688 1.5 20.6938 1.5H1.19385C0.771973 1.5 0.443848 1.17188 0.443848 0.75ZM0.443848 8.25C0.443848 7.875 0.771973 7.5 1.19385 7.5H20.6938C21.0688 7.5 21.4438 7.875 21.4438 8.25C21.4438 8.67188 21.0688 9 20.6938 9H1.19385C0.771973 9 0.443848 8.67188 0.443848 8.25ZM21.4438 15.75C21.4438 16.1719 21.0688 16.5 20.6938 16.5H1.19385C0.771973 16.5 0.443848 16.1719 0.443848 15.75C0.443848 15.375 0.771973 15 1.19385 15H20.6938C21.0688 15 21.4438 15.375 21.4438 15.75Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
