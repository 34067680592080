import { useState } from "react";
import { Button, ButtonVariant } from "../../button";
import "./Explanation.scss";
import { Layout } from "../../layout";
import { Clock, Fist, IconColor, MagicWand, Trophy } from "../../icon";
import { useTranslation } from "react-i18next";
import { getImage } from "../../../utils";

enum ExplanationCardVariant {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SPECIAL = "special",
}

interface ExplanationCardProps {
    readonly variant: ExplanationCardVariant;
    readonly altImage: string;
    readonly imagePath: string;
    readonly icon: JSX.Element;
    readonly title: string;
    readonly description: string;
    readonly extraDescription?: string;
}

function ExplanationCard(props: ExplanationCardProps): JSX.Element {

    const explanationContent = (
        <div className="explanation-content">
            {props.icon}
            <p className="explanation-content-title">{props.title}</p>
            <p className="explanation-content-description">{props.description}</p>

            {
                props.extraDescription &&
                <p className="explanation-content-extra">{props.extraDescription}</p>
            }
        </div>
    );

    return (
        <div className={`explanation-card explanation-${props.variant}`}>
            <Layout rowReverse={true} animated={false}>
                {explanationContent}
                <div className="explanation-video-wrapper">
                    <img
                        style={{
                            width: "auto",
                            maxHeight: "100%",
                        }}
                        src={props.imagePath}
                        alt={props.altImage}
                        draggable={false}
                    />
                </div>
            </Layout>
        </div>
    );
}

export function Explanation(): JSX.Element {

    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const { t } = useTranslation();

    const cards = [
        <ExplanationCard
            title={t("explanation.gamification.title")}
            description={t("explanation.gamification.description")}
            icon={<Trophy color={IconColor.SECONDARY} size={80} />}
            altImage="Gamification"
            imagePath={getImage("explanations/gamification.png")}
            variant={ExplanationCardVariant.SPECIAL}
        />,
        <ExplanationCard
            title={t("explanation.IA.title")}
            description={t("explanation.IA.description")}
            icon={<MagicWand color={IconColor.SPECIAL} size={80} />}
            altImage="AI"
            imagePath={getImage("explanations/ai.png")}
            variant={ExplanationCardVariant.PRIMARY}
        />,
        <ExplanationCard
            title={t("explanation.support.title")}
            description={t("explanation.support.description")}
            icon={<Fist color={IconColor.PRIMARY} size={80} />}
            altImage="Nudge"
            imagePath={getImage("explanations/nudge.png")}
            variant={ExplanationCardVariant.SECONDARY}
            extraDescription={t("explanation.support.extra")}
        />,
        <ExplanationCard
            title={t("explanation.real_time.title")}
            description={t("explanation.real_time.description")}
            icon={<Clock color={IconColor.SECONDARY} size={80} />}
            altImage="Real-time"
            imagePath={getImage("explanations/rt.png")}
            variant={ExplanationCardVariant.SPECIAL}
        />
    ];

    return (
        <div className="explanation-container">
            <div className="buttons-containers">
                <Button
                    variant={activeTabIndex === 0 ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINED}
                    title={t("explanation.gamification.button")}
                    onClick={() => setActiveTabIndex(0)}
                />
                <Button
                    variant={activeTabIndex === 1 ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINED}
                    title={t("explanation.IA.button")}
                    onClick={() => setActiveTabIndex(1)}
                />
                <Button
                    variant={activeTabIndex === 2 ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINED}
                    title={t("explanation.support.button")}
                    onClick={() => setActiveTabIndex(2)}
                />
                <Button
                    variant={activeTabIndex === 3 ? ButtonVariant.PRIMARY : ButtonVariant.OUTLINED}
                    title={t("explanation.real_time.button")}
                    onClick={() => setActiveTabIndex(3)}
                />
            </div>
            <div className="cards-container">
                {cards[activeTabIndex]}
            </div>
        </div>
    );
}
