import { IconProps } from "./interface";

import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 190 / 291;

export function CurlyArrow(props: IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size * HEIGHT_RATIO} viewBox="0 0 291 190" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M279.174 0.265783C278.257 0.265836 277.467 0.954214 277.315 1.95859C270.297 48.5855 252.327 81.7118 223.91 100.421C206.779 111.701 188.334 115.759 173.834 115.865C173.877 112.351 173.238 108.885 171.891 105.572C168.069 96.1687 159.039 90.0948 148.893 90.0954C136.641 90.0961 135.561 97.0598 135.521 99.1944C135.377 107.023 145.375 116.697 162.358 119.336C164.554 119.676 166.893 119.925 169.35 120.062C168.211 125.665 165.087 131.3 160.163 136.354C146.276 150.606 127.571 156.872 111.129 157.679C111.546 155.74 111.56 153.924 111.339 152.288C110.094 143.056 101.043 136.489 92.7448 134.775C83.6966 132.923 78.0937 137.921 77.218 143.832C76.3026 150 80.4188 157.461 91.6763 160.227C95.6183 161.197 99.9924 161.799 104.634 161.978C101.282 166.545 95.0292 170.906 86.1812 174.749C50.7431 190.154 12.4992 170.259 12.1214 170.056C11.1087 169.523 9.93875 169.946 9.49558 171.004C9.05229 172.059 9.50519 173.348 10.513 173.883C12.1358 174.746 50.7407 194.857 87.8418 178.74C100.492 173.243 106.757 167.356 109.545 162.015C127.285 161.567 147.862 155.058 162.99 139.533C168.759 133.617 172.31 126.905 173.44 120.187C173.577 120.187 173.709 120.187 173.845 120.187C210.139 120.185 267.148 96.522 281.249 2.85827C281.424 1.70123 280.682 0.563003 279.599 0.313983C279.456 0.281852 279.313 0.265775 279.174 0.265783ZM169.836 115.79C167.3 115.678 164.92 115.439 162.723 115.097C147.428 112.716 139.408 104.511 139.5 99.5022C139.56 96.2935 143.148 94.3784 149.099 94.378C157.719 94.3775 165.06 99.257 168.255 107.112C169.385 109.887 169.902 112.809 169.836 115.79ZM106.888 157.773C101.659 157.733 96.7379 157.131 92.3708 156.06C83.7748 153.947 80.5441 148.848 81.154 144.729C81.7265 140.861 85.7238 137.652 92.1967 138.991C98.8577 140.365 106.449 145.632 107.401 152.69C107.628 154.388 107.455 156.088 106.888 157.773Z"
                className={`fill-${props.color}`}
            />
            <path
                d="M26.6909 162.645L9.30248 170.599L15.0157 187.449"
                className={`stroke-${props.color}`}
                strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"
            />
        </svg>
    );
}
