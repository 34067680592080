import { IconColor, IconProps } from "./interface";

import "./Icons.scss";

// Calculated on the default width and height of the icon svg
const HEIGHT_RATIO = 37.612999 / 112.336;

export function LogoReachUp(props: {
    readonly size?: number;
    readonly color: IconColor;
    readonly shadowColor: IconColor;
    readonly highlightColor: IconColor;
    readonly upColor: IconColor;

    readonly animate?: boolean;
}) {
    return (
        <svg
            width={props.size ?? "100%"}
            height={props.size ? props.size * HEIGHT_RATIO : "auto"}
            viewBox="0 0 112.33601 37.612999"
            fill="none"
            version="1.1"
            id="ReachUp-animated"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m 0,21.687254 h 5.6173 v 0.9875 c 1.0656,-0.86 2.3566,-1.3056 4.003,-1.3056 1.485,0 2.5828,0.3826 3.3576,1.1788 l -3.2606,4.3002 c -0.3878,-0.3825 -0.9687,-0.6368 -1.7757,-0.6368 -1.4849,0 -2.3243,0.86 -2.3243,2.5801 v 8.4721 H 0 v -15.5749 z"
                className={`fill-${props.color}`}
                id="R"
            />
            <path
                d="m 12.2976,29.458754 c 0,-4.682 3.68,-8.154 8.6194,-8.154 4.9394,0 8.2,3.2807 8.2,7.8033 0,0.605 -0.0646,1.2419 -0.2585,2.102 l -11.0729,0.0637 c 0.484,1.5288 1.7111,2.357 3.5515,2.357 1.5172,0 2.712,-0.5094 3.6476,-1.5607 l 2.9375,2.8982 c -1.5818,1.7519 -3.8092,2.6431 -6.5859,2.6431 -5.3264,0 -9.0394,-3.3445 -9.0394,-8.154 z m 5.488,-1.7838 6.1335,-0.0319 c -0.3547,-1.5607 -1.3556,-2.357 -2.9375,-2.357 -1.5818,0 -2.7443,0.8601 -3.196,2.3889 z"
                className={`fill-${props.color}`}
                id="e"
            />
            <path
                d="m 30.2579,29.490854 c 0,-4.7139 3.1636,-8.1222 7.5545,-8.1222 1.6141,0 3.0344,0.5094 4.0677,1.4013 v -1.0832 h 5.5203 v 15.5749 h -5.5203 v -1.0513 c -1.0333,0.86 -2.4536,1.3694 -4.0677,1.3694 -4.3909,0 -7.5545,-3.4083 -7.5545,-8.0903 z m 11.8476,-0.0319 c 0,-1.8476 -1.2587,-3.1532 -3.0667,-3.1532 -1.8081,0 -3.0991,1.3382 -3.0991,3.1532 0,1.815 1.291,3.1851 3.0668,3.1851 1.7757,0 3.099,-1.3056 3.099,-3.1851 z"
                className={`fill-${props.color}`}
                id="a"
            />
            <path
                d="m 49.5313,29.490654 c 0,-4.7139 3.8738,-8.1859 8.9425,-8.1859 2.4535,0 4.4877,0.8281 6.1342,2.4207 l -3.5514,3.5039 c -0.6455,-0.6369 -1.4526,-0.9557 -2.5828,-0.9557 -1.8404,0 -3.2283,1.2745 -3.2283,3.1851 0,1.9107 1.4202,3.217 3.2283,3.217 1.2271,0 2.0658,-0.4137 2.6797,-1.1151 l 3.5838,3.504 c -1.7758,1.72 -3.7446,2.5482 -6.3274,2.5482 -5.0364,0 -8.8779,-3.4394 -8.8779,-8.1222 z"
                className={`fill-${props.color}`}
                id="c"
            />
            <path
                d="m 66.3516,14.043454 h 5.6172 v 8.5678 c 1.0333,-0.7963 2.4213,-1.2419 4.0031,-1.2419 3.7769,0 6.2305,2.4207 6.2305,5.829 v 10.0647 h -5.6173 v -8.7909 c 0,-1.4332 -0.9686,-2.3251 -2.292,-2.3251 -1.3233,0 -2.3243,0.8919 -2.3243,2.3251 v 8.7909 h -5.6172 z"
                className={`fill-${props.color}`}
                id="h"
            />
            {
                props.animate === false
                    ? (
                        <g>
                            <path
                                d="m 96.8571,14.646354 c -0.172,-3.3996 -0.79,-5.3217999 -1.579,-6.3883999 -0.951,-1.2846 -2.151,-1.3288 -3.121,-1.2194 l -0.012,0.0015 c -1.634,0.1869 -2.336,1.3571 -2.622,2.9995999 -0.179,1.1339 0.411,6.3463 0.531,7.778 0.061,0.513 -0.286,0.8376 -0.665,0.8804 h -0.001 c -0.378,0.0427 -0.79,-0.1935 -0.851,-0.7079 -0.219,-1.8694 -0.474,-6.0783 -0.953,-7.4911 -0.582,-1.6838999 -1.452,-2.7684999 -3.154,-2.5757999 h -0.0058 c -3.7417,0.4297 -3.948,3.3654999 -3.2159,8.5626999 0.5985,4.4929 2.013331,8.884456 8.0087,8.5823 0.72236,-0.03641 0.71712,-0.03702 1.435,-0.1493 4.59574,-0.718772 5.945,-3.3887 6.21,-6.8158 0.085,-1.089 0.06,-2.2548 -0.006,-3.4575 z m -1.955,5.3059 -0.001,0.0036 c -0.423,2.0512 -1.819,2.7178 -1.819,2.7178 -0.646,0.2992 -0.529,-0.3261 -0.041,-0.913 0.359,-0.4311 0.591,-1.2303 0.71,-1.8642 0.163,-0.8695 1.247,-0.8238 1.151,0.0558 z"
                                className={`fill-${props.upColor}`}
                                id="U"
                            />
                            <path
                                d="m 112.2891,13.131054 c -0.528,-4.0356999 -3.135,-5.8455999 -6.951,-5.2456999 -1.741,0.2768 -2.819,0.4796 -4.412,1.1151 -0.712,-0.4195 -1.356,-0.3746 -2.107,-0.2413 -0.672,0.1297 -1.203,0.3123 -1.616,0.5738 0.983,1.4990999 1.344,3.9284999 1.103,7.3838999 -0.131,1.9505 -0.357,3.9503 -1.029,5.6711 0.624,2.3547 1.765,4.0502 4.241,3.6923 1.603,-0.2326 3.042,-0.6072 3.254,-2.4208 0.105,-0.9194 -0.045,-2.0852 -0.302,-3.3322 4.368,-0.2767 8.341,-3.2061 7.82,-7.1962 z m -8.842,2.6135 c -0.024,-0.1638 -0.044,-0.3022 -0.066,-0.4427 l -0.158,-0.9043 c -0.02,-0.1398 -0.044,-0.3021 -0.066,-0.4427 3.5,-0.9346 3.472,1.3035 0.29,1.7889 z m 6.431,-4.285 c -0.129,-0.0804 -0.285,-0.2638 -0.43,-0.4572 -0.3,-0.4021 -0.684,-0.7347 -1.132,-0.9687 -0.476,-0.2492999 -0.974,-0.6136999 -0.68,-0.9520999 0.122,-0.1391 0.312,-0.2 0.496,-0.1732 0.471,0.0667 1.167,0.3616 1.661,1.0969999 0.725,1.076 0.42,1.6665 0.084,1.4542 z"
                                className={`fill-${props.upColor}`}
                                id="P"
                            />
                            <path
                                d="m 80.6973,32.247054 c 1.087945,-2.02e-4 3.201027,-0.0087 3.9399,-0.9115 0.2651,-0.3506 0.3312,-0.797 0.1873,-1.2549 -0.199,-0.6325 -1.103,-1.3136 -2.6181,-1.3136 v 1.4158 c 0.7418,0 1.1229,0.2246 1.2426,0.342 -0.4318,0.3333 -2.4829,0.313 -3.3759,0.3028 -0.2343,-0.0021 -0.4597,-0.0043 -0.6705,-0.0043 -0.2108,0 -0.4369,0.0022 -0.6705,0.0043 -0.893,0.0094 -2.9441,0.0305 -3.3759,-0.3028 0.1197,-0.1174 0.5008,-0.342 1.2426,-0.342 v -1.4158 c -1.5151,0 -2.4191,0.6811 -2.6181,1.3136 -0.1439,0.4587 -0.0778,0.9043 0.1873,1.2549 0.5956,0.7891 1.9865,0.9115 3.9002,0.9115 0.868246,-0.0061 1.786886,0.0022 2.6291,0 z"
                                className={`fill-${props.upColor}`}
                                id="leash"
                            />
                            <path
                                d="m 89.94361,25.051742 c 2.375937,8.671537 -5.244733,12.582952 -10.761971,6.770096"
                                id="string"
                                strokeWidth="1.4"
                                className={`stroke-${props.upColor}`}
                                strokeLinecap="round"
                            />
                            <path
                                transform-origin="97.1945 36.3145"
                                d="m 97.1941,37.262654 c 3.274,0 5.929,-0.4247 5.929,-0.9485 0,-0.5238 -2.655,-0.9484 -5.929,-0.9484 -3.274,0 -5.928,0.4246 -5.928,0.9484 0,0.5238 2.654,0.9485 5.928,0.9485 z"
                                className={`fill-${props.shadowColor}`}
                                id="shadow"
                            />
                        </g>
                    ) : (
                        <g>
                            <g>
                                <path
                                    d="m 96.8571,14.646354 c -0.172,-3.3996 -0.79,-5.3217999 -1.579,-6.3883999 -0.951,-1.2846 -2.151,-1.3288 -3.121,-1.2194 l -0.012,0.0015 c -1.634,0.1869 -2.336,1.3571 -2.622,2.9995999 -0.179,1.1339 0.411,6.3463 0.531,7.778 0.061,0.513 -0.286,0.8376 -0.665,0.8804 h -0.001 c -0.378,0.0427 -0.79,-0.1935 -0.851,-0.7079 -0.219,-1.8694 -0.474,-6.0783 -0.953,-7.4911 -0.582,-1.6838999 -1.452,-2.7684999 -3.154,-2.5757999 h -0.0058 c -3.7417,0.4297 -3.948,3.3654999 -3.2159,8.5626999 0.5985,4.4929 2.013331,8.884456 8.0087,8.5823 0.72236,-0.03641 0.71712,-0.03702 1.435,-0.1493 4.59574,-0.718772 5.945,-3.3887 6.21,-6.8158 0.085,-1.089 0.06,-2.2548 -0.006,-3.4575 z m -1.955,5.3059 -0.001,0.0036 c -0.423,2.0512 -1.819,2.7178 -1.819,2.7178 -0.646,0.2992 -0.529,-0.3261 -0.041,-0.913 0.359,-0.4311 0.591,-1.2303 0.71,-1.8642 0.163,-0.8695 1.247,-0.8238 1.151,0.0558 z"
                                    className={`fill-${props.upColor}`}
                                    id="U"
                                />
                                <path
                                    d="m 112.2891,13.131054 c -0.528,-4.0356999 -3.135,-5.8455999 -6.951,-5.2456999 -1.741,0.2768 -2.819,0.4796 -4.412,1.1151 -0.712,-0.4195 -1.356,-0.3746 -2.107,-0.2413 -0.672,0.1297 -1.203,0.3123 -1.616,0.5738 0.983,1.4990999 1.344,3.9284999 1.103,7.3838999 -0.131,1.9505 -0.357,3.9503 -1.029,5.6711 0.624,2.3547 1.765,4.0502 4.241,3.6923 1.603,-0.2326 3.042,-0.6072 3.254,-2.4208 0.105,-0.9194 -0.045,-2.0852 -0.302,-3.3322 4.368,-0.2767 8.341,-3.2061 7.82,-7.1962 z m -8.842,2.6135 c -0.024,-0.1638 -0.044,-0.3022 -0.066,-0.4427 l -0.158,-0.9043 c -0.02,-0.1398 -0.044,-0.3021 -0.066,-0.4427 3.5,-0.9346 3.472,1.3035 0.29,1.7889 z m 6.431,-4.285 c -0.129,-0.0804 -0.285,-0.2638 -0.43,-0.4572 -0.3,-0.4021 -0.684,-0.7347 -1.132,-0.9687 -0.476,-0.2492999 -0.974,-0.6136999 -0.68,-0.9520999 0.122,-0.1391 0.312,-0.2 0.496,-0.1732 0.471,0.0667 1.167,0.3616 1.661,1.0969999 0.725,1.076 0.42,1.6665 0.084,1.4542 z"
                                    className={`fill-${props.upColor}`}
                                    id="P"
                                />
                                <animateTransform
                                    attributeName="transform"
                                    values="0 0; 0 -7; 0 0"
                                    type="translate"
                                    dur="5s"
                                    repeatCount="indefinite"
                                />
                            </g>
                            <path
                                transform-origin="97.1945 36.3145"
                                d="m 97.1941,37.262654 c 3.274,0 5.929,-0.4247 5.929,-0.9485 0,-0.5238 -2.655,-0.9484 -5.929,-0.9484 -3.274,0 -5.928,0.4246 -5.928,0.9484 0,0.5238 2.654,0.9485 5.928,0.9485 z"
                                className={`fill-${props.shadowColor}`}
                                id="shadow"
                            >
                                <animateTransform
                                    attributeName="transform"
                                    values="1; 0.6; 1"
                                    type="scale"
                                    dur="5s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                d="m 89.94361,25.051742 c 2.375937,8.671537 -5.244733,12.582952 -10.761971,6.770096"
                                id="string"
                                strokeWidth="1.4"
                                className={`stroke-${props.upColor}`}
                                strokeLinecap="round"
                            >
                                <animate
                                    attributeName="d"
                                    values="m 89.94361,25.051742 c 2.375937,8.671537 -5.244733,12.582952 -10.761971,6.770096; m 89.333384,18.072281 c 0.886697,8.096467 -5.895496,16.959792 -10.151745,13.749557; m 89.94361,25.051742 c 2.375937,8.671537 -5.244733,12.582952 -10.761971,6.770096"
                                    dur="5s"
                                    repeatCount="indefinite"
                                />
                            </path>
                            <path
                                d="m 80.6973,32.247054 c 1.087945,-2.02e-4 3.201027,-0.0087 3.9399,-0.9115 0.2651,-0.3506 0.3312,-0.797 0.1873,-1.2549 -0.199,-0.6325 -1.103,-1.3136 -2.6181,-1.3136 v 1.4158 c 0.7418,0 1.1229,0.2246 1.2426,0.342 -0.4318,0.3333 -2.4829,0.313 -3.3759,0.3028 -0.2343,-0.0021 -0.4597,-0.0043 -0.6705,-0.0043 -0.2108,0 -0.4369,0.0022 -0.6705,0.0043 -0.893,0.0094 -2.9441,0.0305 -3.3759,-0.3028 0.1197,-0.1174 0.5008,-0.342 1.2426,-0.342 v -1.4158 c -1.5151,0 -2.4191,0.6811 -2.6181,1.3136 -0.1439,0.4587 -0.0778,0.9043 0.1873,1.2549 0.5956,0.7891 1.9865,0.9115 3.9002,0.9115 0.868246,-0.0061 1.786886,0.0022 2.6291,0 z"
                                className={`fill-${props.upColor}`}
                                id="leash"
                            />
                        </g>
                    )
            }

        </svg>
    );
}
