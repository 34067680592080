import { useTranslation } from "react-i18next";
import { CurlyArrow, IconColor, Up } from "../../icon";
import "./Statement.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { getVideo } from "../../../utils";

export function Statement(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className="statement">
            <div className="video-player-container">
                <ScrollAnimation
                    animateIn={"zoomIn"}
                    animatePreScroll={true}
                    duration={0.8}
                >
                    <video
                        controls={true}
                        style={{
                            maxWidth: "100%",
                            maxHeight: "100%",

                            objectFit: "cover",
                        }}

                        autoPlay={false}
                        muted={false}
                        loop={false}
                        playsInline={true}
                    >
                        <source src={getVideo("temoignage/kusmitea.mp4")} type="video/mp4" />
                    </video>
                </ScrollAnimation>
            </div>
            <div className="video-description">
                <p className="quotation">{t("statement.quotation")}</p>
                <div className="credits">
                    <p className="author">Philippe Lauga</p>
                    <p className="position">{t("statement.position")}</p>
                </div>
            </div>
            <div className="statement-up-background">
                <Up color={IconColor.BACKGROUND_UP} />
            </div>
            <div className="curly-arrow">
                <CurlyArrow size={300} color={IconColor.SPECIAL} />
            </div>
        </div>
    );
}
