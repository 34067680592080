import { useTranslation } from "react-i18next";
import { getImage } from "../../../utils";
import { Card } from "../../card";
import { Image } from "../../image";
import { Layout } from "../../layout";

import "./Feature.scss";

export function Comparison(): JSX.Element {

    const { t } = useTranslation();

    return (
        <div className="feature-container">
            <Layout alignedBySide={true}>
                <Card
                    title={t("features.comparison.title")}
                    description={t("features.comparison.description")}
                />
                <div className="feature-image-container">
                    <Image
                        source={getImage("Card_2.png")}
                        alt={t("features.comparison.title")}
                    />
                    {/** TO DO : Add badges */}
                </div>
            </Layout>
        </div>
    );
}
