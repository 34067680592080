export function Image(props: {
    readonly source: string;
    readonly alt?: string;
    readonly title?: string;
    readonly draggable?: boolean;
}): JSX.Element {
    return (
        <img
            src={props.source}
            alt={props.alt ?? ""}
            width={"100%"}
            height={"100%"}
            title={props.title ?? ""}
            draggable={props.draggable ?? true}
        />
    );
}
