import { IconColor, IconProps } from "./interface";

export function LinkedinLogo(props: {
    backgroundColor: IconColor;
} & IconProps): JSX.Element {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.756836" width="50" height="50" rx="15" className={`fill-${props.backgroundColor}`} />
            <path
                d="M17.2675 39.7567H11.4625V21.0629H17.2675V39.7567ZM14.3619 18.5129C12.5056 18.5129 11 16.9754 11 15.1192C11 14.2276 11.3542 13.3725 11.9847 12.742C12.6151 12.1115 13.4703 11.7573 14.3619 11.7573C15.2535 11.7573 16.1086 12.1115 16.7391 12.742C17.3696 13.3725 17.7238 14.2276 17.7238 15.1192C17.7238 16.9754 16.2175 18.5129 14.3619 18.5129ZM38.9937 39.7567H33.2013V30.6567C33.2013 28.4879 33.1575 25.7067 30.1831 25.7067C27.165 25.7067 26.7025 28.0629 26.7025 30.5004V39.7567H20.9038V21.0629H26.4712V23.6129H26.5525C27.3275 22.1442 29.2206 20.5942 32.045 20.5942C37.92 20.5942 39 24.4629 39 29.4879V39.7567H38.9937Z"
                className={`fill-${props.color}`}
            />
        </svg>
    );
}
