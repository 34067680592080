import { useTranslation } from "react-i18next";
import { Accordion } from "../../accordion";
import { AnimatedVideo } from "../../animatedVideo";
import { Layout } from "../../layout";
import "./Solution.scss";

export function Solution(): JSX.Element {

    const { t } = useTranslation();

    return (
        <div className="solution">
            <Layout animated={false}>
                <div className="solution-explanations">
                    <p className="solution-title">{t("solution.title")}</p>
                    <Accordion
                        title={t("solution.real_time.title")}
                        openByDefault
                    >
                        <div className="accordion-text-container">
                            <p className="accordion-text">
                                {t("solution.real_time.description")}
                            </p>
                            <p className="accordion-text-example">
                                {t("solution.real_time.example")}
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title={t("solution.profitability.title")}>
                        <div className="accordion-text-container">
                            <p className="accordion-text">
                                {t("solution.profitability.description")}
                            </p>
                            <p className="accordion-text-example">
                                {t("solution.profitability.example")}
                            </p>
                        </div>
                    </Accordion>
                    <Accordion title={t("solution.commitment.title")}>
                        <div className="accordion-text-container">
                            <p className="accordion-text">
                                {t("solution.commitment.description")}
                            </p>
                            <p className="accordion-text-example">
                                {t("solution.commitment.example")}
                            </p>
                        </div>
                    </Accordion>
                </div>
                <div className="video-wrapper">
                    <AnimatedVideo />
                </div>
            </Layout>
        </div>
    );
}
