import "./Badge.scss";

export enum BadgeVariant {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SPECIAL = "special",
}

export function Badge(props: {
    readonly text: string;
    /**
     * Defines the colors of the speech bubble.
     * - Primary: blue background with white text.
     * - Secondary: white background with blue text.
     * - Special: yellow background with white text.
     * Default is primary.
     * */
    readonly variant?: BadgeVariant;
}): JSX.Element {
    return (
        <div className={`badge ${props.variant ?? BadgeVariant.PRIMARY}`}>
            <p className={"badge-text"}>
                {props.text}
            </p>
        </div>
    );
}
